<template>
	<section class="checkout_layout">
		<header>
			<div class="area content">
				<a tabindex="0" class="logo" :href="localeLink('/')"></a>
				<span class="label">{{ localeLang("checkout.orderPayment") }}</span>
			</div>
		</header>
		<slot />
		<footer>
			<div class="area content">
				<div class="left">
					<span v-html="wareHouseTxt"></span>&nbsp;
					<a :href="localeLink('/policies/terms_of_use.html')" tabindex="0" target="_blank">
						{{ localeLang("pages.confirmOrder.termsOfUse") }}
					</a>
					|
					<a :href="localeLink('/policies/privacy_policy.html')" tabindex="0" target="_blank">
						{{ localeLang("pages.confirmOrder.privacyPolicy") }}
					</a>
				</div>
				<div v-if="!isCn" class="right">
					<img v-if="isDeAll" src="https://img-en.fs.com/includes/templates/fiberstore/images/ssl_icon.png" />
					<a
						v-else
						tabindex="-1"
						href="https://seal.digicert.com/seals/popup/?tag=Er-2fIoO&url=www.fs.com&lang=en&cbr=1625818103042"
						target="_blank">
						<img src="https://seal.digicert.com/seals/cascade/?s=vyT8OdM_,15,m,www.fs.com&type=static" />
					</a>
					<a
						tabindex="0"
						class="ml-5"
						href="https://www.trustedsite.com/verify?host=www.fs.com&utm_campaign=mfes_redirect&utm_medium=referral&utm_source=mcafeesecure.com"
						target="_blank">
						<img src="https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/McAfee.svg" />
					</a>
				</div>
			</div>
		</footer>
	</section>
</template>
<script setup lang="ts">
// import { schemaOrganization } from "@/constants/schemaOrganization";
const websiteStore = useWebsiteStore();
const { website, warehouse, isCn } = storeToRefs(websiteStore);
// TODO：
// useHead({
// 	__dangerouslyDisableSanitizers: ["title", "meta", "script", "link", "style"],
// 	script: [
// 		{
// 			type: "application/ld+json",
// 			json: schemaOrganization(website)
// 		}
// 	]
// });
const localeLink = useLocaleLink();
const localeLang = useLocaleLang();
const isDeAll = ["de", "de-en"].includes(website.value);
const wareHouseTxt = computed(() => {
	let str = "";
	switch (warehouse.value) {
		case "US":
			str = "FS.com Inc.";
			break;
		case "DE":
			str = "FS.com GmbH";
			break;
		case "CN":
			if (website.value === "jp") {
				str = "FS JAPAN株式会社";
			} else if (website.value === "ru") {
				str = "FS.com Ltd.";
			} else {
				str = "深圳市飞速创新技术股份有限公司";
			}
			break;
		case "AU":
			str = "FS.com Pty Ltd";
			break;
		case "UK":
			str = "FS.com Innovation Ltd";
			break;
		case "RU":
			str = "FS.com Ltd.";
			break;
		case "SG":
			str = "FS Tech Pte Ltd.";
			break;
		case "JP":
			str = "FS JAPAN株式会社";
			break;
		default:
			break;
	}
	if (website.value === "uk") {
		str = "FS.com Innovation Ltd";
	}
	return str;
});
</script>
<style lang="scss" scoped>
.checkout_layout {
	background-color: $bgColor6;
}
.area {
	@include width1420;
	@include mobile {
		width: 100%;
		padding: 0 16px;
	}
}
header {
	background: $bgColor6;
	border-bottom: 1px solid $borderColor1;
	.content {
		padding: 40px 0;
		display: flex;
		align-items: center;
		.logo {
			width: 75px;
			height: 36px;
			margin-right: 20px;
			background: url("https://img-en.fs.com/includes/templates/fiberstore/images/new-pc-img/fs-logo-pc.png") no-repeat
				center/cover;
		}
		.label {
			@include font20;
			font-weight: 600;
		}
	}
}

footer {
	background: $bgColor6;
	border-top: 1px solid $borderColor1;

	.content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 80px;
		.left,
		.right {
			display: flex;
			align-items: center;
		}
		.left {
			@include font13();
			> a {
				color: $textColor1;
			}
		}
		.right {
			.ml-5 {
				margin-left: 5px;
			}
			img {
				height: 30px;
			}
		}
	}
}
@include padMobile {
	header {
		background: transparent;
		.content {
			padding: 40px 24px;
		}
	}
}
@include mobile {
	header .content {
		padding: 24px 0;

		justify-content: center;
	}
	footer {
		padding-top: 8px;
		.content {
			height: 125px;
			display: block;
			.left {
				padding: 20px 0;
			}
			.left,
			.right {
				justify-content: center;
			}
		}
	}
}
</style>
